<template>
<Card>
    <template #content>
		<p>Los pagos han sido procesados con éxito. Si desea enviar comprobantes a los correos electrónicos registrados presiones el botón de "Enviar".</p>
        <Divider />      		    
        <DataTable :value="pagos" editMode="cell" dataKey="rec.id_movimiento" v-model:selection="selectedPagos" :rowClass="rowClass" v-model:expandedRows="expandedRows" responsiveLayout="scroll" class="p-datatable-sm"
		filterDisplay="menu">
			<template #header>        
				<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
					<div class="p-input-icon-right">
						<Button label="Enviar y Finalizar" icon="pi pi-envelope" class="p-button-secondary" @click="dlgEnvioEmail=true" :disabled="!puedeEnviarEmails"/>
					</div>
					<div class="p-input-icon-right">
						<Button label="Finalizar" icon="pi pi-check" class="p-button-success" @click="finalizar"/>
					</div>		
				</div>
			</template>		
			<template #empty>
				No hay pagos procesados
			</template>		
            <Column selectionMode="multiple" headerStyle="width: 3rem"/>
			<Column field="tipo_movimiento" header="Tipo" />
			<Column field="serie" header="Serie" /> 
			<Column field="rec.no_docto" header="#"/>
            <Column field="cta_habiente" :header="tituloCtaHabiente"/>       
			<Column field="rec.referencia" header="Referencia"/>
            <Column field="rec.total" header="Total"> 
				<template #body="slotProps" sortable>
                   	<div class="p-d-flex p-jc-end">
                        <div>{{slotProps.data.rec.id_moneda}} {{formatCurrency(slotProps.data.rec.total)}}</div>
                    </div>                									
				</template>
			</Column>	
            <!-- <template #expansion="slotProps">
                <div class="orders-subtable">
                    <DataTable :value="slotProps.data.detalle" dataKey="id_mov_det" responsiveLayout="scroll" class="p-datatable-sm"  selectionMode="single">
                        <Column field="codigo" header="Código" style="flex: 0 0 8rem"></Column>
                        <Column field="producto" header="Producto" style="flex: 0 0 2rem"></Column>
                        <Column field="cantidad" header="Cantidad" style="flex: 0 0 2rem"></Column>
						<Column field="precio_unitario" header="Precio U." style="flex: 0 0 4rem">
                          <template #body="slotProps">
                                {{formatCurrency(slotProps.data.precio_unitario)}}
                            </template>
                        </Column>
						<Column field="total" header="Total">					
                            <template #body="slotProps">
                                {{formatCurrency(slotProps.data.total)}}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template> -->
			<ColumnGroup type="footer">
				<Row>
					<Column footer="Documentos:" :colspan="4" footerStyle="text-align:right" />
					<Column :footer="totalTrxAEmitir" />
					<Column :footer="montoTotalAOperar" :colspan="3" footerStyle="text-align:right" />
					<!-- <Column :footer="montoTotalAOperar" />					 -->
				</Row>
			</ColumnGroup>					
        </DataTable>				
    </template>
    <template #footer>        
        <div class="p-d-flex p-jc-between">
            <Button label="Enviar" @click="Enviar" class="p-button-warning" icon="pi pi-cog" v-show="!errores" :disabled="totalTrxAEmitir==0"/>
        </div>
    </template>
</Card>

<Dialog v-model:visible="dlgEnvioEmail" :modal="true">
	<template #header>
		Confirmación de envío de notificación
	</template>
	<div class="p-d-flex">
		<i class="pi pi-envelope p-mr-2" style="fontSize: 2rem"></i>
		<p>¿Está seguro que desea enviar los comprobantes seleccionado(s)?</p>
	</div>
	<template #footer>
		<div class="p-field p-col">
			<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="dlgEnvioEmail=false"/>
			<Button label="Enviar" icon="pi pi-send" class="p-button-text" @click="enviarACtahabientesSeleccionados"/>
		</div>
	</template>
</Dialog>	

<Dialog v-model:visible="enviandoEmailsDlg" :showHeader="true" :modal="true" :closable="false">			
	<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
		<div class="p-d-flex p-ai-center p-jc-center">
			<!-- <i class="pi pi-send p-m-2" style="font-size: 2rem"></i> -->
			<img src="/assets/layout/images/message.gif" width="64" height="64"/>
		</div>
		<div class="flex align-items-center justify-content-center mt-5">				
		<ProgressBar :value="secuencia">										
			<div class="font-light">
			Enviando {{secuencia}} de {{selectedPagos.length}}
			</div>
		</ProgressBar>
		</div>
	</div>
</Dialog>


</template>
<script>
import { CURRENT_APP_MODE, MDS_APP_MODE} from '../../service/constants';
import CxcService from '../../service/cxc.service';
import MdsService from '../../service/mds.service';
import InvService from '../../service/inv.service';
import Divider from 'primevue/divider';

export default {
    name: 'CxcImpResumen',
    components: {
        Divider
    },    
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id_perfil: '',
					id_moneda: '',
					archivo: '',
					errores: false,
					movs: [],					
				}
			}
		}
	},          
    data() {        
        return {         
            appMode: CURRENT_APP_MODE,
			tituloCtaHabiente: (CURRENT_APP_MODE == MDS_APP_MODE? 'Inmueble': 'Cta.Habiente'),
			secuencia: 1,
			procesandoDlg: false,
			id_moneda: this.formData.id_moneda,
			errores: this.formData.errores,
			pagos: this.formData.movs,
			selectedPagos: [],
			gran_total: 0.0,
            dlgEnvioEmail: false,
			enviandoEmailsDlg: false,
			expandedRows: [],
        }
    },
    async mounted()
    {
		this.gran_total = 0;
		for(var i = 0; i < this.pagos.length; i++)
		{
			this.gran_total = this.gran_total + this.pagos[i].rec.total;			
		}
    },
	computed:{
		totalTrxAEmitir()
		{
			if(this.pagos) {
				return this.pagos.length;
			} else {
				return 0;
			}
		},
		montoTotalAOperar()
		{
			return `Total: ${this.id_moneda} ${this.formatCurrency(this.gran_total)}`;
		},
		puedeEnviarEmails() {
			if(this.selectedPagos) {
				return (this.selectedPagos.length > 0)
			}  else {
				return false;
			}
		}        		
	},    
    methods: {
		async procesar() {

			this.secuencia = 1;
			this.procesandoDlg = true;
			try {
				
				let movs = await CxcService.procesarPagosBatch(this.$store.state.auth.currentAppCtx, this.pagos);

				let formData = {
					id_perfil: this.id_perfil,
					id_moneda: this.id_moneda,					
					archivo: this.archivo,
					errores: (errores.length > 0),
					movs: movs
				};
				this.procesandoDlg = false;
				
				this.nextPage();
			} catch(err) {
				this.procesandoDlg = false;
				this.secuencia = 0;
			}
		},
        async nextPage() {
			// var emisiones = await this.cargos.filter(cargo => cargo.emitido);

			let formData = {
				id_perfil: this.id_perfil,
				id_moneda: this.id_moneda,
				archivo: this.archivo,
				movs: this.pagos,
			};

            this.$emit('nextPage', {condiciones: formData, pageIndex: 1});
        },
        finalizar() {
			this.$router.push('/');
		},
		async enviarACtahabientesSeleccionados()
		{
			this.dlgEnvioEmail	= false;
			try
			{
				this.enviandoEmailsDlg = true;
				this.secuencia = 1;
				var notif;

				for(var i = 0; i < this.selectedPagos.length; i++)
				{												
					notif = {						
						id: this.selectedPagos[i].rec.id_movimiento,
						emails: []
					};

					if(this.appMode == MDS_APP_MODE) { // Multidomus
						notif.emails =  await MdsService.getInmueblesEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedPagos[i].rec.id_cta_habiente_info);
					} else {
						notif.emails = await InvService.getCtaHabienteEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedPagos[i].rec.id_cta_habiente_info);
					}

					if(notif.emails.length > 0) {
						await CxcService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);
					}
					
					this.secuencia++;
				}
				this.secuencia = 1;
				this.enviandoEmailsDlg = false;
				this.finalizar();
			}
			catch(error) {
				this.dlgEnvioEmail = false;
				console.log(error);
			}
		},
        onRowSelect(data) {
            data.aplicar = !data.aplicar;				
			return data.aplicar;			
        },
		rowClass(data)
		{
			var className = (data.emitir?"emitir-row":"regular-row");
			return className;
		},
		findIndexMovimiento(idMovimiento)
		{
			var index = -1;
			for(var i=0; i<this.pagos.length; i++)
			if(this.pagos[i].id_movimiento === idMovimiento) {
				index = i;
				break;
			}

			return index;
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},	        
        prevPage() {
            this.$emit('prevPage', {pageIndex: 1});
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}

::v-deep(.regular-row) {
	font-weight: 300;
	background: white;
}

::v-deep(.emitir-row) {
	font-weight: bold;
	background:white;
}

</style>